import { ICallout } from "azure-devops-ui/Callout";
import { IReadonlyObservableValue } from "azure-devops-ui/Core/Observable";
import { css } from "azure-devops-ui/Util";
import { IPoint, Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { Callout } from "../../../common/components/callout/callout";
import { Observer } from "../../../common/components/observer/observer";
import { EventContext } from "../../../common/contexts/event";
import { useMouseTracker } from "../../../common/hooks/usemousetracker";
import { useObservable, useSubscription } from "../../../common/hooks/useobservable";
import { useResize } from "../../../common/hooks/useresize";
import { DateContext } from "../../contexts/date";
import { IDateRange } from "../daterange/daterange";

import "./scrubber.css";

const { TimelineLabel } = window.Resources.Common;

const yearMonthFormat = Intl.DateTimeFormat(undefined, { year: "numeric", month: "long" });

export interface IScrubberProps {
  /**
   * The end date represents the last date that should be rendered in the scubber.
   */
  endDate: IReadonlyObservableValue<Date | undefined>;

  /**
   * onTargetDate is called when the user selects a date in the scrubber.
   */
  onTargetDate: (targetDate: Date) => void;

  /**
   * onWheel event handler associatated with the scrubber. This allows the
   * caller to capture wheel events that originate from the scrubber. This is
   * particularly valuable when the scrubber is positioned outside the
   * element it is scrolling.
   */
  onWheel?: (event: React.WheelEvent<HTMLElement>) => void;

  /**
   * By default the scrubber is transparent and doesn't show up unless the user
   * hovers over the scrubber. Setting showContents forces the scrubber to become
   * visible.
   */
  showContents?: IReadonlyObservableValue<boolean> | boolean;

  /**
   * The start date represents the first date that should be rendered in the scrubber.
   */
  startDate: IReadonlyObservableValue<Date | undefined>;

  /**
   * The viewport range represents the range of time that is current being shown.
   * The scrubber uses this to represent the current date time.
   */
  viewportRange: IReadonlyObservableValue<IDateRange | undefined>;
}

export function Scrubber(props: IScrubberProps): React.ReactElement {
  const { endDate, onTargetDate, onWheel, showContents, startDate, viewportRange } = props;

  const dateContext = React.useContext(DateContext);
  const eventContext = React.useContext(EventContext);

  const bulletCount = React.useRef(0);
  const channelElement = React.useRef<HTMLDivElement>(null);
  const scrubberElement = React.useRef<HTMLDivElement>(null);

  const [bullets, setBullets] = React.useState<React.ReactElement[]>([<span className="photo-scrubber-bullet flex-noshrink" key={0} />]);
  const [hoverDate, setHoverDate] = useObservable<Date | undefined>(undefined);
  const [hoverPoint, setHoverPoint] = useObservable<IPoint>({ x: 0, y: 0 });
  const [targetClassName, setTargetClassName] = React.useState<string | undefined>();

  const { hasMouse, onMouseEnter, onMouseLeave } = useMouseTracker();

  // Make sure we are tracking the availability of start and end dates.
  const _endDate = useSubscription(endDate);
  const _startDate = useSubscription(startDate);
  const _viewportRange = useSubscription(viewportRange, () => setTargetClassName("transition"));
  const _showContents = useSubscription(showContents);

  // Make sure we are handling positioning appropriately in an rtl environment.
  const rtlModifier = document.documentElement.dir === "rtl" ? -1 : 1;

  useResize(
    scrubberElement,
    React.useCallback((entries: ResizeObserverEntry[]) => {
      const updatedBulletCount = Math.ceil(entries[0].contentRect.height / 68);

      if (updatedBulletCount !== bulletCount.current) {
        const updatedBullets: React.ReactElement[] = [];

        for (let index = 0; index < updatedBulletCount; index++) {
          updatedBullets.push(<span className="photo-scrubber-bullet flex-noshrink" key={index} />);
        }

        bulletCount.current = updatedBulletCount;
        setBullets(updatedBullets);
      }
    }, [])
  );

  let scrolledBottom = true;
  let scrolledTop = true;

  // Determine where the active marker should be (start/end, or in channel).
  if (_endDate && _startDate && _viewportRange) {
    scrolledBottom = _viewportRange.endDate.getTime() <= _endDate.getTime();
    scrolledTop = _viewportRange.startDate.getTime() >= _startDate.getTime();
  }

  // @NOTE: You need the outer & inner elements because you are using the outer
  // element to manage resize, where the inner element is for visibility. You
  // can't watch the size of the element that is being hidden.
  return (
    <section
      aria-label={TimelineLabel}
      className={css(
        "photo-scrubber flex-column flex-align-center flex-noshrink overflow-hidden cursor-pointer",
        _showContents && "photo-scrubber-visible"
      )}
      onWheel={onWheel}
      ref={scrubberElement}
    >
      <span
        className={css(
          "photo-scrubber-date flex-noshrink body-s secondary-text font-weight-semibold padding-4 padding-top-8",
          scrolledTop && "active"
        )}
        onClick={() => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "scrubberStart" });
          _startDate && onTargetDate(_startDate);
        }}
      >
        {_startDate && dateContext.formatDate(_startDate, "year")}
      </span>
      <div
        className="photo-scrubber-channel relative flex-column flex-align-center flex-grow overflow-hidden"
        onClick={() => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "scrubberChannel" });
          hoverDate.value && onTargetDate(hoverDate.value);
        }}
        onDragStart={onDragStart}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        ref={channelElement}
      >
        {bullets}
        <Observer values={{ hoverDate, hoverPoint, viewportRange }}>
          {({ hoverDate, hoverPoint, viewportRange }) => {
            let targetDate: Date | undefined;
            let { x, y } = hoverPoint;

            if (hasMouse) {
              targetDate = hoverDate;
            } else if (_showContents && channelElement.current && _endDate && _startDate && viewportRange) {
              const clientRect = channelElement.current.getBoundingClientRect();
              const timeRange = _startDate.getTime() - _endDate.getTime();
              const rangePercentage = (_startDate.getTime() - viewportRange.startDate.getTime()) / timeRange;

              targetDate = viewportRange.startDate;
              x = rtlModifier === -1 ? clientRect.left : clientRect.right;
              y = clientRect.top + clientRect.height * rangePercentage;
            }

            return targetDate ? <ScrubberTarget anchorPoint={{ x, y }} className={targetClassName} targetDate={targetDate} /> : null;
          }}
        </Observer>
      </div>
      <span
        className={css(
          "photo-scrubber-date flex-noshrink body-s secondary-text font-weight-semibold padding-4 padding-bottom-8",
          scrolledBottom && "active"
        )}
        onClick={() => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "scrubberEnd" });
          _endDate && onTargetDate(_endDate);
        }}
      >
        {_endDate && dateContext.formatDate(_endDate, "year")}
      </span>
    </section>
  );

  function onMouseMove(event: React.MouseEvent) {
    if (_startDate && _endDate) {
      const clientRect = channelElement.current!.getBoundingClientRect();
      const percentage = Math.max(0, event.clientY - clientRect.top) / clientRect.height;
      const targetDate = new Date(_startDate.getTime() - (_startDate.getTime() - _endDate.getTime()) * percentage);

      setHoverDate(targetDate);
      setHoverPoint({
        x: rtlModifier === -1 ? clientRect.left : clientRect.right,
        y: Math.max(4, Math.min(event.clientY, clientRect.top + clientRect.height - 4))
      });
      setTargetClassName(undefined);
    }
  }
}

function onDragStart(event: React.DragEvent) {
  // The scrubber should not be a draggable item, but sometimes a text selection from the scrubber will initiate a drag
  event.preventDefault();
}

interface IScrubberTargetProps {
  anchorPoint: IPoint;
  className?: string;
  targetDate: Date;
}

function ScrubberTarget(props: IScrubberTargetProps): React.ReactElement {
  const { anchorPoint, className, targetDate } = props;

  const dateCallout = React.useRef<ICallout | undefined>(undefined);

  // Make sure we are handling positioning appropriately in an rtl environment.
  const rtlModifier = document.documentElement.dir === "rtl" ? -1 : 1;

  React.useLayoutEffect(() => {
    dateCallout.current!.updateLayout();
  });

  return (
    <Callout
      anchorPoint={anchorPoint}
      className={css("photo-scrubber-target pointer-events-none", className)}
      componentRef={dateCallout}
      contentClassName="flex-row rounded-4 transparent"
      calloutOrigin={
        rtlModifier === -1 ? { horizontal: Location.start, vertical: Location.center } : { horizontal: Location.end, vertical: Location.center }
      }
      fixedLayout={true}
    >
      <span className="photo-scrubber-target-date body-s font-weight-semibold padding-horizontal-8 padding-vertical-4 rounded-4 white-space-nowrap depth-8">
        {yearMonthFormat.format(targetDate)}
      </span>
      <span className="photo-scrubber-mark rounded-4 margin-left-8" />
    </Callout>
  );
}
